@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  font-family: freight-sans-pro-lights, sans-serif;
  background: #0a192f;
}

.quiz-box {
  width: 20rem;
  height: 25rem;
  color: #41dfff;
  display: flex;
  font-size: x-large;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  margin: auto;
  border-radius: 0.1rem;
  border-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );
  border-width: 3px;
  border-style: solid;
  border-image-slice: 1;
}

.question {
  padding: 1rem;
}

.result {
  padding: 1rem;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );
  border-image-slice: 1;
  width: 100%;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #0a192f;
  color: #5eead4;
  font-family: 'Press Start 2P';
  font-size: large;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.2), 0 4px 6px -4px rgb(0 0 0 / 0.2);
}

.btn:active,
.btn-result:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.btn-result {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );
  border-image-slice: 1;
  width: 100%;
  padding: 1rem;
  background-color: #0a192f;
  color: #5eead4;
  font-family: 'Press Start 2P';
  font-size: large;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.2), 0 4px 6px -4px rgb(0 0 0 / 0.2);
}

.btn:hover,
.btn-result:hover {
  background-color: rgba(65, 223, 255, 0.1);
  transition: ease-in 200ms;
}

.result-box {
  width: 21rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #41dfff;
  text-align: center;
  padding: 1rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.6), 0 4px 6px -4px rgb(0 0 0 / 0.6);
  border-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );
  border-width: 3px;
  border-style: solid;
  border-image-slice: 1;
}

.title {
  font-family: 'Press Start 2P';
}

.about {
  font-family: 'Press Start 2P';
}

.contact {
  font-family: 'Press Start 2P';
}

.result-title {
  font-family: 'Press Start 2P';
}

.privacy {
  background: #0a192f;
}

.terms {
  background: #0a192f;
}
